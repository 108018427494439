import React, { useState } from "react"

import { graphql, useStaticQuery, navigate } from "gatsby"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ButtonBase from "@material-ui/core/ButtonBase"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import { Footer } from "../components/footer"
import SEO from "../components/seo"
import { maxWidth } from "../components/maxwidth"

import { GatsbyImage } from "gatsby-plugin-image"
import { colors } from "../components/colors"

const useStyles = makeStyles(theme => ({
  banner: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    "& .overlay": {
      backgroundColor: "rgba(36,120,153,0.65)",
      mixBlenderMode: "multiply",
    },

    "& .bannerText": {
      marginTop: "90px",
      color: "#fff",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  },
  catContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexWrap: "wrap",
    backgroundColor: colors.ice,

    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },

    "& .categories": {
      width: "100%",
      maxWidth,
      padding: "50px 0px",

      "& .container": {
        width: "100%",
        padding: "0 5%",
        margin: "0 auto",
        textAlign: "center",

        [theme.breakpoints.up("sm")]: {
          paddingLeft: "5%",
          width: "70%",
        },

        "& .title": {
          color: colors.gray,
          fontWeight: "bolder",
        },

        "& .description": {
          margin: "15px 0",
          color: colors.grayText,
        },

        "& .button": {
          borderWidth: "2px",
        },
      },
    },
  },
  clientes: {
    backgroundColor: "#d7ddc4",
    position: "relative",

    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    "& .overlay": {
      backgroundColor: "#123782",
      opacity: "0.82",
      position: "absolute",
    },

    "& .title": {
      color: "#FFF",
      textAlign: "center",
      marginBottom: "20px",
      fontWeight: "bolder",
    },

    "& .desc": {
      color: "#fff",
      textAlign: "center",
      padding: "0 15%",

      [theme.breakpoints.down("xs")]: {
        padding: "0 5%",
      },
    },

    "& .content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "50px 30px",
      textAlign: "center",
      justifyContent: "start",

      "& h5": {
        fontWeight: "bold",
        margin: "15px 0",
        height: "100px",
      },

      "& p": {
        marginBottom: "20px",
        height: "150px",
      },

      "& button": {
        borderWidth: "2px",
        textTransform: "none",
      },
    },
  },
  equipe: {
    padding: "50px 0px",

    "& .title": {
      color: colors.gray,
      textAlign: "center",
      marginBottom: "20px",
      fontWeight: "bolder",
    },

    "& .card": {
      borderRadius: "6px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      boxShadow: theme.shadows[6],

      "& h4": {
        color: "#fff",
        textAlign: "center",
        fontSize: "1.5rem",
        backgroundColor: colors.azul,
        padding: "0.75rem",
        transition: "background-color 0.5s",
        width: "100%",
        height: "95px",
      },
    },

    "& .card:hover": {
      "& h4": {
        backgroundColor: colors.gray,
        transition: "background-color 0.5s",
      },
    },

    "& .button": {
      backgroundColor: colors.verde,
    },
  },
  advogados: {
    "& h4, p": {
      padding: "2rem",
    },

    "& h4": {
      backgroundColor: colors.azul,
      color: "#FFF",
      fontWeight: "bolder",
    },

    "& p": {
      color: colors.grayText,
    },
  },
  contato: {
    backgroundColor: colors.azul,

    "& .title": {
      textAlign: "center",
      color: "#FFF",
      fontWeight: "bolder",
    },

    "& iframe": {
      width: "100%",
      height: "100%",
    },
  },
}))

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner_home.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      banner2: file(relativePath: { eq: "banner_home2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      gustavo: file(relativePath: { eq: "gustavo.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      pedro: file(relativePath: { eq: "pedro.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      assessoria: file(relativePath: { eq: "assessoria.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      civil: file(relativePath: { eq: "civil.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      tributario: file(relativePath: { eq: "tributario.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      trabalhista: file(relativePath: { eq: "trabalhista.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const classes = useStyles()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [advogado, setAdv] = useState("gustavo")

  const gotoPage = path => () => {
    navigate(path)
  }

  const gotoAtuacao = path => () => {
    navigate(`/atuacao/${path}`)
  }

  const openAdv = adv => () => {
    setAdv(adv)
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  return (
    <Layout>
      <SEO
        title="Home"
        description="O Escritório é resultado do desejo de oferecer serviços
                jurídicos assertivos e eficazes. Pensando assim, os advogados
                Dr. Vanzete e Dr. Gustavo resolveram unir esforços, aliando a
                larga experiência jurídica com a inovação do mercado."
      />
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        height={{ xs: "100vh" }}
        mt={{ xs: 0 }}
        className={classes.banner}
        position="relative"
      >
        <GatsbyImage
          image={data.banner.childImageSharp.gatsbyImageData}
          alt="banner home"
          objectPosition="center bottom"
        />
        <Box width={1} height={1} position="absolute" className="overlay"></Box>
        <Box
          width={1}
          maxWidth={maxWidth}
          position="absolute"
          display="flex"
          flexWrap="wrap"
          height="100%"
          alignItems="center"
          px={{ xs: "10px", sm: "20px" }}
        >
          <Box width={1} p={{ xs: "30px", sm: "50px" }}>
            <Typography variant="h4" className="bannerText">
            “Um bom advogado vai além do serviço prestado. É a confiança para uma parceria duradoura.” - Beatriz Mello.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width={1} display="flex" justifyContent="center" flexWrap="wrap">
        <Box className={`${classes.catContainer}`}>
          <Box className="categories">
            <Box className="container">
              <Typography variant="h4" className="title">
                QUEM SOMOS
              </Typography>
              <Typography variant="body1" className="description">
                O Escritório é resultado do desejo de oferecer serviços
                jurídicos assertivos e eficazes. Pensando assim, os advogados
                Dr. Vanzete e Dr. Gustavo resolveram unir esforços, aliando a
                larga experiência jurídica com a inovação do mercado.
              </Typography>
              <Typography variant="body1" className="description">
                Com um novo conceito de gestão e planejamento estratégico dos
                serviços jurídicos, atuamos de forma ágil, efetiva e
                personalizada na defesa dos interesses do cliente, para que você
                possa se dedicar em tempo integral ao que realmente importa em
                seu negócio.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                className="button"
                onClick={gotoPage("/escritorio")}
              >
                clique e saiba mais
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        className={classes.clientes}
      >
        <Box width={1} height={1} position="absolute" zIndex="0">
          <GatsbyImage
            image={data.banner2.childImageSharp.gatsbyImageData}
            alt="banner home"
            objectPosition="center bottom"
          />
          <Box
            width={1}
            height={1}
            position="absolute"
            className="overlay"
            top="0"
          ></Box>
        </Box>
        <Box
          width={1}
          maxWidth={maxWidth}
          px={{ xs: "10px", sm: "20px" }}
          color="#fff"
          py="80px"
          zIndex="10"
        >
          <Typography variant="h4" className="title">
            ÁREAS DE ATUAÇÃO
          </Typography>
          <Typography variant="body1" className="desc">
            Atuamos em todo território nacional, no âmbito Federal, Estadual e
            Municipal, consultivo e contencioso, administrativo e judicial,
            desdobrando-se, entre outros, nos seguintes temas
          </Typography>
          <Box width={1} mt="40px">
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent className="content">
                    <Box width="50px" height="50px">
                      <GatsbyImage
                        image={data.assessoria.childImageSharp.gatsbyImageData}
                        alt="banner home"
                      />
                    </Box>
                    <Typography variant="h5">
                      Assessoria e Consultoria Jurídica
                    </Typography>
                    <Typography variant="body1">
                      A assessoria jurídica permite a prevenção de litígios o
                      que traz um aspecto duplamente lucrativo...
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={gotoAtuacao("assessoria")}
                    >
                      Continue Lendo
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent className="content">
                    <Box width="50px" height="50px">
                      <GatsbyImage
                        image={data.civil.childImageSharp.gatsbyImageData}
                        alt="banner home"
                      />
                    </Box>
                    <Typography variant="h5">
                      Direito Civil, Família e Sucessões
                    </Typography>
                    <Typography variant="body1">
                      No campo do Direito Civil buscamos as melhores soluções
                      aos nossos clientes...
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={gotoAtuacao("civil")}
                    >
                      Continue Lendo
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent className="content">
                    <Box width="50px" height="50px">
                      <GatsbyImage
                        image={data.tributario.childImageSharp.gatsbyImageData}
                        alt="banner home"
                      />
                    </Box>
                    <Typography variant="h5">Direito Tributário</Typography>
                    <Typography variant="body1">
                      Realizamos o patrocínio de processos judiciais e
                      administrativos relacionados a tributos federais,
                      estaduais e municipais...
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={gotoAtuacao("tributario")}
                    >
                      Continue Lendo
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent className="content">
                    <Box width="50px" height="50px">
                      <GatsbyImage
                        image={data.trabalhista.childImageSharp.gatsbyImageData}
                        alt="banner home"
                      />
                    </Box>
                    <Typography variant="h5">Contratos</Typography>
                    <Typography variant="body1">
                      A relação contratual faz parte da vida, desde contratos de
                      trabalho, prestação de serviço, locação, compra e venda,
                      etc...
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={gotoAtuacao("contratos")}
                    >
                      Continue Lendo
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box width={1} display="flex" justifyContent="center">
        <Box
          width={1}
          maxWidth={maxWidth}
          px={{ xs: "10px", sm: "20px" }}
          className={classes.equipe}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h4" className="title">
            ADVOGADOS
          </Typography>
          <Grid container spacing={5} justify="center" alignItems="center">
            <Grid item sm={3}>
              <ButtonBase className="card" onClick={openAdv("gustavo")}>
                <Box width={1}>
                  <GatsbyImage
                    image={data.pedro.childImageSharp.gatsbyImageData}
                    alt="Gustavo Marques de Sá Gomes"
                    objectFit="contain"
                  />
                </Box>
                <Typography variant="h4">
                  Gustavo Marques de Sá Gomes
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item sm={3}>
              <ButtonBase className="card" onClick={openAdv("pedro")}>
                <Box width={1}>
                  <GatsbyImage
                    image={data.gustavo.childImageSharp.gatsbyImageData}
                    alt="Vanzete Gomes Filho"
                    objectFit="contain"
                  />
                </Box>
                <Typography variant="h4">Vanzete Gomes Filho</Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        bgcolor={colors.azul}
      >
        <Box
          width={1}
          maxWidth={maxWidth}
          px={{ xs: "10px", sm: "20px" }}
          className={classes.contato}
          display="flex"
          flexDirection="column"
          py="50px"
        >
          <Typography variant="h4" className="title">
            LOCALIZAÇÃO
          </Typography>
          <Box width={1} mt="20px" height="400px">
            <iframe
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?q=Rua%20Abraham%20Lincoln%2C%20n%C2%BA%20229%2C%20Jd.%20Guarulhos%2C%20Guarulhos%20-%20SP%2C%20CEP%2007090-100&amp;t=m&amp;z=17&amp;output=embed&amp;iwloc=near"
              title="Rua Abraham Lincoln, nº 229, Jd. Guarulhos, Guarulhos - SP, CEP 07090-100"
              aria-label="Rua Abraham Lincoln, nº 229, Jd. Guarulhos, Guarulhos - SP, CEP 07090-100"
            ></iframe>
          </Box>
        </Box>
      </Box>
      <Footer />
      <Dialog open={dialogOpen} onClose={closeDialog} onOpen={() => {}}>
        {advogado === "gustavo" ? <Gustavo /> : <Pedro />}
      </Dialog>
    </Layout>
  )
}

const Gustavo = () => {
  const classes = useStyles()
  return (
    <Box className={classes.advogados}>
      <Typography variant="h4">
        GUSTAVO MARQUES
        <br />
        DE SÁ GOMES
      </Typography>
      <Typography variant="body1">
        Graduado pela Universidade Presbiteriana Mackenzie. Especialista em
        Direito Tributário pela Faculdade Damásio e em Direito Processual Civil
        pela Universidade Presbiteriana Mackenzie. Curso de Contratos (Contract
        Law: From Trust To Promise To Contract) pela Harvard Law School (USA),
        Contratos: Visão Negocial e Prática pela Fundação Getúlio Vargas – SP,
        Contratos e Proteção de Dados pelo Data Privacy Brasil, Direito Digital
        e Proteção de Dados pela PUC-SP, Legal Growth Hacking pela Edevo e Legal
        Design pelo Legal HUB. Atualmente é Vice-Presidente da Comissão de
        Inovação Gestão e Tecnologia da OAB-Guarulhos e mentor convidado na
        Escola de Negócios do Sebrae – SP. Sócio fundador do escritório.
      </Typography>
    </Box>
  )
}

const Pedro = () => {
  const classes = useStyles()
  return (
    <Box className={classes.advogados}>
      <Typography variant="h4">
        VANZETE
        <br />
        GOMES FILHO
      </Typography>
      <Typography variant="body1">
        Graduado pela Faculdade Brás Cubas, conta com mais de 30 anos de
        experiência no mercado jurídico, coordenando as áreas de Direito Civil,
        Família e Sucessões.
      </Typography>
    </Box>
  )
}

export default IndexPage
